// Imports => Constants
import { ENDPOINTS } from '@constants'
import { dataError } from 'bfj/src/events'

export class TenantAPI {
  constructor(NoAuthClient, config) {
    this.NoAuthClient = NoAuthClient
    this.config = config
  }

  get_tenant = (data) => {
    return this.NoAuthClient.get(`${ENDPOINTS.TENANT.INITIATE}`, {
      params: { ...data },
    }).then((response) => response.data)
  }

  initiate_with_slug = (data) => {
    return this.NoAuthClient.get(`${ENDPOINTS.TENANT.INITIATE}?slug=${data}`).then((response) => response)
  }

  initiate_with_origin = () => {
    return this.NoAuthClient.get(`${ENDPOINTS.TENANT.INITIATE}`).then((response) => response)
  }
}

export default TenantAPI
