// Imports => React
import React, { useEffect, useState, useMemo, useRef } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { withStore } from '@stores'
import { observer } from 'mobx-react-lite'
import { Routes, Route, Navigate, useLocation, useParams } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import clsx from 'clsx'
import loadable from '@loadable/component'

// Imports => SCSS
import '@styles/index.scss'
import Favicon from '@assets/images/favicon.ico'

// Imports => Constants
import { AUTHENTICATION_ROUTES, DEFAULT_ROUTE, KEYS, ROUTES, TITLES } from '@constants'

// Imports => Utilities
import { AcApplyTheme, AcIsSet, AcLoadFontFamily, AcIsArray, AcSetDocumentTitle } from '@utils'

// Imports => Molecules
const AcToasterHoc = loadable(() => import('@molecules/ac-toaster-hoc/ac-toaster-hoc.web'))
const AcModal = loadable(() => import('@molecules/ac-modal/ac-modal.web'))
const AcConfirmationModal = loadable(() => import('@molecules/ac-confirmation-modal/ac-confirmation-modal.web'))
const AcDrawer = loadable(() => import('@molecules/ac-drawer/ac-drawer.web'))

// Imports => Components
const AcHeader = loadable(() => import('@components/ac-header/ac-header.web'))

// Imports => Atoms
const AcErrorBoundary = loadable(() => import('@atoms/ac-error-boundary/ac-error-boundary.web'))
const AcPrivateRoute = loadable(() => import('@atoms/ac-private-route/ac-private-route.web'))
const AcScrollHOC = loadable(() => import('@atoms/ac-scroll-hoc/ac-scroll-hoc.web'))
const AcButton = loadable(() => import('@atoms/ac-button/ac-button.web'))

const _CLASSES = {
  ROOT: 'ac-root',
  MAIN: 'ac-app',
  NAVIGATION_VISIBLE: 'ac-app--navigation-visible',
  SCROLLING_DOWN: 'ac-app--scrolling-down',
  ROUTE: {
    SECTION: 'ac-route__section',
  },
}

const konami = {
  pattern: [
    'ArrowUp',
    'ArrowUp',
    'ArrowDown',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowLeft',
    'ArrowRight',
    'b',
    'a',
  ],
  current: 0,
}

const App = ({ tenant, applyThemeElement, store }) => {
  const $navigation = useRef(null)

  const location = useLocation()
  const params = useParams()

  const { ui } = store

  const init = async (tenantProp) => {
    const re = new RegExp('(?:http[s]*\\:\\/\\/)*(.*?)\\.(?=[^\\/]*\\..{2,5})')
    // const slug = window.location.hostname.match(re) || ['redbrick']

    // if (AcIsSet(slug) && AcIsArray(slug)) {
    // AcLoadFontFamily()

    if (params?.tenant) tenant = params.tenant

    const aTenant = tenantProp || tenant
    if ((aTenant && aTenant !== null) || undefined) {
      await store.tenant.get_tenant_slug(aTenant)
    }

    await store.tenant.initiate().then((tenant) => {
      window.tenant = tenant

      if (AcIsSet(tenant)) {
        if (AcIsSet(tenant.name)) AcSetDocumentTitle(tenant.name)
        if (AcIsSet(tenant.theming) && AcIsSet(tenant.theming)) {
          AcApplyTheme(tenant.theming, applyThemeElement)

          if (AcIsSet(tenant?.avatar)) {
            const avatarLinkElement = document.createElement('link')
            avatarLinkElement.rel = 'preload'
            avatarLinkElement.as = 'image'

            avatarLinkElement.href = tenant?.avatar
            document.head.appendChild(avatarLinkElement)
          }

          if (AcIsSet(tenant?.theming?.['--branding-mask'])) {
            // const brandMaskPath = require(`@assets/images/theming/brand-mask-${tenant.slug}-1x.svg`)
            applyThemeElement.style.setProperty('--tenant-branding-mask', `url('${tenant.theming['--branding-mask']}')`)

            const brandingMaskLinkElement = document.createElement('link')
            brandingMaskLinkElement.rel = 'preload'
            brandingMaskLinkElement.as = 'image'

            brandingMaskLinkElement.href = tenant.theming['--branding-mask']
            document.head.appendChild(brandingMaskLinkElement)
          }

          if (AcIsSet(tenant.theming?.logo)) {
            ui.setInstance(KEYS.HEADER, {
              ...ui.current_header,
              logo_visual: tenant.theming?.logo,
            })
          }
        }
      }
    })
    // }
  }

  useEffect(() => {
    init(tenant)
  }, [tenant])

  useEffect(() => {
    handleRouteChanged()
  }, [location])

  const handleRouteChanged = (event) => {
    ui.reset(KEYS.MODAL)
  }

  const getRouteSectionClassNames = useMemo(() => {
    return clsx(_CLASSES.ROUTE.SECTION)
  }, [])

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN, ui.current_scroll && ui.current_scroll.down && _CLASSES.SCROLLING_DOWN)
  }, [ui.current_scroll])

  const renderHeader = useMemo(() => {
    const { header } = ui

    const options = Object.assign({}, header)

    return <AcHeader {...options} />
  }, [ui.header])

  const renderDrawer = useMemo(() => {
    return (
      <AcDrawer
        key={ui.current_drawer.id}
        id={ui.current_drawer.id}
        visible={ui.current_drawer.visible}
        {...ui.current_drawer}
        ltr={false}
      />
    )
  }, [ui.current_drawer, ui.current_drawer.id, ui.current_drawer.actions, ui.current_drawer.visible])

  const renderModal = useMemo(() => {
    const { current_modal } = ui
    let body = ui.current_modal.body

    if (AcIsSet(current_modal.tag) && current_modal.tag === KEYS.CONFIRM) {
      body = <AcConfirmationModal {...current_modal.props} />
    }

    return (
      <AcModal {...ui.current_modal} callback={ui.current_modal.callback}>
        {body}
      </AcModal>
    )
  }, [
    ui.current_modal,
    ui.current_modal.body,
    ui.current_modal.title,
    ui.current_modal.closeable,
    ui.current_modal.visible,
  ])

  const renderToasterHoc = useMemo(() => {
    return <AcToasterHoc queue={store.toasters.queue} callback={store.toasters.remove} />
  }, [store.toasters, store.toasters.queue])

  const renderDefaultRoute = useMemo(() => {
    return (
      <Route
        key={`route-${DEFAULT_ROUTE.id}`}
        path={DEFAULT_ROUTE.path}
        element={
          <AcPrivateRoute
            key={`default-route-${DEFAULT_ROUTE.id}`}
            component={DEFAULT_ROUTE.component}
            forbidden={DEFAULT_ROUTE.forbidden}
            authorized={true}
            exact
          />
        }
      />
    )
  }, [])

  const renderRoutes = useMemo(() => {
    const collection = ROUTES
    let result = []
    let key

    for (key in collection) {
      const item = collection[key]

      const object = (
        <Route
          key={`route-${item.id}`}
          path={item.path}
          element={
            <AcPrivateRoute
              key={`route-${item.id}`}
              name={item.name}
              path={item.path}
              component={item.component}
              forbidden={false}
              authorized={true}
            />
          }
        />
      )
      result.push(object)
    }

    return result
  }, [])

  return (
    <AcErrorBoundary screen={location.pathname}>
      <AcScrollHOC>
        <main className={getMainClassNames}>
          <section id={KEYS.SCROLLER} className={getRouteSectionClassNames}>
            <TransitionGroup component={null} appear>
              <CSSTransition key={location.key} timeout={300} classNames={'fade'} unmountOnExit>
                <div key={location.key}>
                  <Routes location={location}>
                    {renderRoutes}
                    {renderDefaultRoute}
                  </Routes>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </section>

          {renderDrawer}

          {renderModal}

          {renderToasterHoc}
        </main>
      </AcScrollHOC>
    </AcErrorBoundary>
  )
}

export default withStore(observer(App))
