// Imports => Vendor
import axios from 'axios'

// Imports => API
import FlowAPI from '@api/flow.api'
import TenantAPI from '@api/tenant.api'

/*
const onUploadProgress = (event) => {
	console.group('[Axios] => fn.onUploadProgress');
	console.log('Event: ', event);
	console.groupEnd();
};

const onDownloadProgress = (event) => {
	console.group('[Axios] => fn.onDownloadProgress');
	console.log('Event: ', event);
	console.groupEnd();
};
*/
let _timeOut = null
let _errorTokens = []

const unauthenticatedState = (state) => {
	const cancelRequestsEvent = new CustomEvent('cancelRequests')
	window.dispatchEvent(cancelRequestsEvent)

	const unauthenticatedEvent = new CustomEvent('unAuthenticate', {
		detail: state,
	})
	window.dispatchEvent(unauthenticatedEvent)
}

const cancelRequests = () => {
	const collection = _errorTokens
	const len = collection.length
	let n = 0

	for (n; n < len; n++) {
		const instance = collection[n]
		if (instance && instance.cancel) instance.cancel()
	}

	_errorTokens = []
}

const addInterceptors = (requestClient) => {
	requestClient.interceptors.request.use(
		async (config) => {
			const source = axios.CancelToken.source()
			config.cancelToken = source.token
			_errorTokens.push(source)

			return config
		},
		(error) => {
			if (error.response && error.response.status === 401) {
				clearTimeout(_timeOut)

				const cancelRequestsEvent = new CustomEvent('cancelRequests')
				window.dispatchEvent(cancelRequestsEvent)

				_timeOut = setTimeout(() => unauthenticatedState(false), 500)
			}
			return Promise.reject(error)
		},
	)
}

const addErrorToken = (requestClient) => {
	requestClient.interceptors.request.use(
		(config) => {
			const source = axios.CancelToken.source()
			config.cancelToken = source.token
			_errorTokens.push(source)

			return config
		},
		(error) => {
			return Promise.reject(error)
		},
	)
}

export default (config) => {
	const NoAuthClient = axios.create({
		...config.api_no_auth,
	})

	const Client = axios.create({
		...config.api,
	})
	// addInterceptors(Client);
	// addErrorToken(Client);

	const PlainClient = axios.create({
		...config.plain,
	})
	// addInterceptors(PlainClient);
	// addErrorToken(PlainClient);

	const DownloadClient = axios.create({
		...config.download,
	})
	// addInterceptors(DownloadClient);
	// addErrorToken(DownloadClient);

	const UploadClient = axios.create({
		...config.upload,
	})
	// addInterceptors(UploadClient);
	// addErrorToken(UploadClient);

	window.addEventListener('cancelRequests', cancelRequests, false)

	const flow = new FlowAPI(NoAuthClient, config)
	const tenant = new TenantAPI(NoAuthClient, config)

	return {
		flow,
		tenant,
	}
}
