// Imports => Constants
import { ENDPOINTS } from '@constants'

export class FlowAPI {
  constructor(NoAuthClient, config) {
    this.NoAuthClient = NoAuthClient
    this.config = config
  }

  initiateFlow = (trackingId) => {
    return this.NoAuthClient.get(`${ENDPOINTS.FLOW.INITIATE_FLOW}?squeezely_id=${trackingId}`).then((res) => res?.data)
  }

  initiate = (flowId) => {
    return this.NoAuthClient.post(ENDPOINTS.FLOW.INITIATE, { flow_id: flowId }).then((response) => response.data.data)
  }

  next_question = (data, flowId) => {
    // TODO: add flowId to post
    return this.NoAuthClient.post(ENDPOINTS.FLOW.NEXT_QUESTION, {
      data,
      flow_id: flowId,
    }).then((response) => {
      return response.data
    })
  }

  previous_question = (data, flowId) => {
    // TODO: add flowId to post?
    return this.NoAuthClient.post(ENDPOINTS.FLOW.PREVIOUS_QUESTION, {
      data,
      flow_id: flowId,
    }).then((response) => response.data.data)
  }

  share_results = (flowResults) => {
    const data = [
      Object.keys(flowResults).map((result) => ({
        question_id: parseInt(result),
        option_uuid: flowResults[result],
      })),
    ]
    return this.NoAuthClient.post(`${ENDPOINTS.FLOW.SHARE_RESULTS}`, { data: data[0] }).then((response) => response)
  }

  shared_flow = (hash) => {
    return this.NoAuthClient.get(`${ENDPOINTS.FLOW.SHARED_FLOW}/${hash}`).then((response) => response)
  }

  mailResults = (emailAddress, flowId, hasConsent, results) => {
    return this.NoAuthClient.post(`${ENDPOINTS.FLOW.MAIL}`, {
      data: [...results],
      email: emailAddress,
      flow_id: flowId,
      has_consent: hasConsent,
    }).then((res) => res.data)
  }
}

export default FlowAPI
